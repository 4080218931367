import React, { Component } from 'react';
import * as _ from 'lodash';
import services from '../../../API/services';
import { iUser } from '../../../API/interfaces';
import { authService } from '../../Auth/Auth.service';
import { toasterService } from '../../Shared/Toaster/Toaster.service';
import utils from '../../Shared/utils';
import Alert from '../Alert/Alert';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BlackBtn, RedBtn } from '../../Auth/Onboarding/_styled';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';

interface iSettingsFormProps {
  user: iUser;
  fields: string[];
}

interface iSettingsFormState {
  schema: any;
  userData: { firstName: string; lastName: string; email: string; personalData: any };
  initialUserData: { firstName: string; lastName: string; email: string; personalData: any };
  errors: { firstName?: string; lastName?: string; email?: string; birth?: string };
  alertMsg: string | null;
  isUpdating: boolean;
}

class SettingsFormComponent extends Component<iSettingsFormProps, iSettingsFormState> {
  constructor(props: iSettingsFormProps) {
    super(props);
    const { user } = props;

    const initialUserData = {
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      personalData: {
        birth: user.personalData?.birth ? dayjs(user.personalData.birth) : null,
        ...user.personalData,
      },
    };

    this.state = {
      schema: utils.getFormValidationSchema('settings'),
      userData: initialUserData,
      initialUserData: initialUserData,
      errors: {},
      alertMsg: null,
      isUpdating: false,
    };
  }

  handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    this.setState({ isUpdating: true });

    const { userData, initialUserData } = this.state;
    const { user } = this.props;

    const birthChanged = !dayjs(initialUserData.personalData.birth).isSame(userData.personalData.birth);

    const userFieldsChanged =
      userData.firstName !== initialUserData.firstName ||
      userData.lastName !== initialUserData.lastName ||
      userData.email !== initialUserData.email;

    try {
      if (birthChanged) {
        const personalDataUpdate = {
          ...user.personalData,
          birth: dayjs.isDayjs(userData.personalData.birth) ? userData.personalData.birth.toDate() : null,
        };
        services.updatePersonalData(personalDataUpdate);
        authService.updatePersonalData(personalDataUpdate);
      }

      if (userFieldsChanged) {
        const { firstName, lastName, email } = userData;
        services.updateUser({ firstName, lastName, email });
        authService.login({ ...user, first_name: firstName, last_name: lastName, email });
      }

      this.setState({
        alertMsg: 'User information has been updated.',
        initialUserData: { ...userData },
        isUpdating: false,
      });
    } catch (error) {
      toasterService.addErrorToast('Failed to update user data.');
      this.setState({ isUpdating: false });
    }
  };

  handleDateChange = (date: Dayjs | null) => {
    const { userData } = this.state;
    this.setState({
      userData: {
        ...userData,
        personalData: {
          ...userData.personalData,
          birth: date,
        },
      },
    });
  };

  handleValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string) => {
    const { userData } = this.state;

    this.setState({
      userData: { ...userData, [fieldName]: event.target.value },
    });
  };

  hideMessage = () => {
    this.setState({ alertMsg: null });
  };

  handleReset = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { initialUserData } = this.state;
    this.setState({
      userData: initialUserData,
      errors: {},
    });
  };

  generateInput = ([id, fieldName, label, placeholder]: string[]) => {
    const { userData, errors } = this.state;

    if (fieldName === 'birth') {
      const birthValue = userData.personalData.birth ? dayjs(userData.personalData.birth) : null;
      return (
        <div className="form-group birth-input mb-px-2 w-100" key={id}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <DatePicker label={label} value={birthValue} onChange={this.handleDateChange} />
          </LocalizationProvider>
        </div>
      );
    }

    return (
      <div className="form-group mb-px-2 w-100" key={id}>
        <TextField
          id={'input_' + id}
          label={label}
          variant="outlined"
          placeholder={placeholder}
          value={userData[fieldName]}
          onChange={(e) => this.handleValueChange(e, fieldName)}
          error={!!errors?.[fieldName]}
          helperText={errors?.[fieldName]}
        />
      </div>
    );
  };

  generateInputs = () => {
    const { fields } = this.props;

    const allFields = [
      ['fname', 'firstName', 'First Name', 'Enter your First Name'],
      ['lname', 'lastName', 'Last Name', 'Enter your Last Name'],
      ['email', 'email', 'Email', 'Enter your Email'],
      ['birth', 'birth', 'Date of Birth', 'Enter your Date of Birth'],
    ];

    return allFields
      .filter(([, fieldName]) => fields.includes(fieldName))
      .map((options) => this.generateInput(options));
  };

  componentDidUpdate() {
    const { schema, userData, errors } = this.state;

    try {
      const newErrors = utils.validateFields(schema, userData);
      if (!_.isEqual(errors, newErrors)) {
        this.setState({ errors: newErrors });
      }
    } catch (error) {
      console.error('Error validating fields:', error);
    }
  }

  isUserDataUnchanged = () => {
    const { userData, initialUserData } = this.state;

    const isValidDayjs = (date: any): date is Dayjs => {
      return dayjs.isDayjs(date);
    };

    const birthComparison =
      isValidDayjs(userData.personalData.birth) && isValidDayjs(initialUserData.personalData.birth)
        ? userData.personalData.birth.isSame(initialUserData.personalData.birth)
        : userData.personalData.birth === initialUserData.personalData.birth;

    return (
      userData.firstName === initialUserData.firstName &&
      userData.lastName === initialUserData.lastName &&
      userData.email === initialUserData.email &&
      birthComparison
    );
  };

  render() {
    const { alertMsg, errors, isUpdating } = this.state;
    const { fields } = this.props;

    const isUnchanged = this.isUserDataUnchanged();
    const isEmailRender = fields.includes('email');

    return (
      <div className="settings-form">
        <div className="row">
          <div className="col-12">
            {alertMsg && <Alert alertType="success" text={alertMsg} handleClose={this.hideMessage} />}
          </div>
        </div>

        <form onSubmit={this.handleSave}>
          <div className="form-inputs">{this.generateInputs()}</div>
          <div
            className={isEmailRender ? 'd-flex justify-content-start' : 'd-flex justify-content-center'}
            style={{ gap: '10px', marginTop: '20px' }}
          >
            {!isUpdating && !isUnchanged && (
              <>
                <RedBtn type="submit" disabled={!_.isEmpty(errors)}>
                  {isEmailRender ? 'Update Email' : 'Update Changes'}
                </RedBtn>
                <BlackBtn type="button" onClick={this.handleReset}>
                  Don't save
                </BlackBtn>
              </>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default SettingsFormComponent;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Tab, TabContainer } from '../../Shared/TabContainer/TabContainer';
import { X } from 'react-feather';
import { Dialog, DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import { iWheel, iWheelExt, WheelMemberRole, WheelMemberRoleType } from '../../../API/interfaces';
import { Text } from '../../Shared/Typography/Typography';
import { FiLink } from 'react-icons/fi';
import { IoQrCodeOutline } from 'react-icons/io5';
import { LinkInput } from './LinkInput';
import styled from 'styled-components';
import { Button } from '../../Shared/Buttons/Button';
import { QRCode } from 'react-qrcode-logo';
import qrLogo from '../../../Assets/images/qr-logo.png';
import utils, { getWheelRole } from '../../Shared/utils';
import { createShareLink } from '../common/InviteButton/utils';
import { RadioButton } from '../../Shared/RadioButton';
import { WHEEL_ACCESS_TYPES } from '../../../constants';
import { analytics } from '../../../analytics/analytics';

interface WheelInvitationProps {
  wheel: iWheelExt | iWheel;
  onCancel: () => any;
  classes: any;
  userId: string;
}

export const InviteToWheelModalComponent: React.FC<WheelInvitationProps> = ({ wheel, onCancel, classes, userId }) => {
  const ref = useRef();
  const isPhone = utils.checkIsPhone();
  const size = 220;
  const userWheelRole = wheel ? getWheelRole(wheel, userId) : '';
  const [inviteRole, setInviteRole] = useState<WheelMemberRoleType>(WheelMemberRole.MEMBER);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const role = () => {
      if (wheel?.access === WHEEL_ACCESS_TYPES.PUBLIC) return WheelMemberRole.MEMBER;
      if (userWheelRole === WheelMemberRole.MEMBER) return WheelMemberRole.ACCOUNTABILITY_BUDDY;
      return WheelMemberRole.MEMBER;
    };
    setInviteRole(role());
  }, [userWheelRole, wheel]);

  const url = useMemo(() => (wheel ? createShareLink(wheel, inviteRole, userId) : ''), [wheel, inviteRole, userId]);

  const onImageDownload = () => {
    // @ts-ignore
    const canvas = ref.current.children[0]?.children[0];
    const pngFile = canvas.toDataURL('image/png');
    const downloadLink = document.createElement('a');
    downloadLink.download = 'QrCode';
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };

  const onChangeInviteRole = (role: WheelMemberRoleType) => {
    setInviteRole(role);
  };

  const dialogTitle = `Invite ${userWheelRole === WheelMemberRole.MEMBER ? 'buddy' : 'member'} to the wheel`;

  const roleDescription = {
    member:
      'Members are able to complete check ins for this wheel and can view their personal results to reflect on their journey.',
    accountability_buddy:
      'Invite a buddy who can see your check-in data, reply to comments and assist you on your journey of overcoming obstacles and achieving your goals.',
  };

  const modalBodyText = {
    link: {
      member:
        'Copy this invitation link and share it with the people you want to join the wheel or complete this check in.',
      accountability_buddy: 'Copy this invite link and share it with whoever you wish to be your accountability buddy.',
    },
    qrCode: {
      member:
        'Download the invitation QR code and share it with people you wish to invite and join the wheel. Or ask people to scan it from here.',
      accountability_buddy:
        'Download the invitation QR code and share it with a buddy who can see your check-in data. Or ask a buddy to scan it from here.',
    },
  };

  const isWearableWheel = wheel?.access === WHEEL_ACCESS_TYPES.WEARABLE;

  return (
    <Dialog open={Boolean(wheel)} onClose={onCancel} classes={{ paper: classes.dialog }} className={classes.modal}>
      <X className={classes.closeBtn} size={18} color="#7b858e" onClick={onCancel} />
      {wheel?.access === WHEEL_ACCESS_TYPES.PUBLIC || isWearableWheel || userWheelRole === WheelMemberRole.MEMBER ? (
        <>
          <DialogTitle classes={{ root: classes.title }}>
            <Text size="24px" mSize="16px">
              {dialogTitle}
            </Text>
          </DialogTitle>
          {wheel?.access !== WHEEL_ACCESS_TYPES.WEARABLE && (
            <Text size="14px" margin="0 40px">
              {roleDescription[inviteRole]}
            </Text>
          )}
        </>
      ) : (
        <RoleControls>
          <RadioButton
            isBoldSelected
            textSize="16px"
            value={WheelMemberRole.MEMBER}
            selected={inviteRole}
            text="Invite Members to Wheel"
            onChange={(e) => {
              analytics.shareWheel();
              onChangeInviteRole(e);
            }}
            infoIconTooltipText={roleDescription[WheelMemberRole.MEMBER]}
          />
          <RadioButton
            isBoldSelected
            textSize="16px"
            value={WheelMemberRole.ACCOUNTABILITY_BUDDY}
            selected={inviteRole}
            text="Invite Accountability Buddy to Wheel"
            onChange={(e) => {
              analytics.clickInviteBuddy();
              onChangeInviteRole(e);
            }}
            infoIconTooltipText={roleDescription[WheelMemberRole.ACCOUNTABILITY_BUDDY]}
          />
        </RoleControls>
      )}
      <DialogContent classes={{ root: classes.content }}>
        <TabContainer defaultTabIndex={activeTab} onChangeTab={setActiveTab} key={inviteRole}>
          <Tab
            className={classes.tab}
            title={
              <span className="d-flex-inline align-items-center position-relative">
                <FiLink className="mr-1 position-relative" style={{ top: '-1px' }} />
                <Text size="16px!important" mSize="12px!important">
                  Copy URL link
                </Text>
              </span>
            }
          >
            <ContentWrapper>
              <div className="mb-4">
                <Text size="16px" mSize="12px">
                  {modalBodyText.link[inviteRole]}
                </Text>
              </div>
              <div className="pt-3">
                <LinkInput url={url} inviteRole={inviteRole}></LinkInput>
              </div>
            </ContentWrapper>
          </Tab>
          <Tab
            className={classes.tab}
            title={
              <span className="d-flex-inline align-items-center position-relative">
                <IoQrCodeOutline className="mr-1 position-relative" style={{ top: '-1px' }} />
                <Text size="16px!important" mSize="12px!important">
                  Download QR code
                </Text>
              </span>
            }
          >
            <ContentWrapper>
              <div className="d-flex align-items-center flex-column flex-lg-row">
                <div style={{ height: size, width: size }} className="flex-shrink-0">
                  <div ref={ref}>
                    <div>
                      <QRCode
                        value={url}
                        size={size - 20}
                        quietZone={10}
                        bgColor="#fefaf2"
                        logoImage={qrLogo}
                        logoWidth={size * 0.25}
                        logoHeight={size * 0.25}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="ml-0 ml-lg-4 d-flex flex-column justify-content-between mt-4 mt-lg-0 text-center text-lg-left"
                  style={{ height: !isPhone ? size - 20 : size - 80 }}
                >
                  <Text size="16px" mSize="12px" color="#0c2337">
                    {modalBodyText.qrCode[inviteRole]}
                  </Text>
                  <div className="d-flex justify-content-center justify-content-lg-end mt-2">
                    <Button onClick={onImageDownload}>Download QR code</Button>
                  </div>
                </div>
              </div>
            </ContentWrapper>
          </Tab>
        </TabContainer>
      </DialogContent>
    </Dialog>
  );
};

const ContentWrapper = styled.div`
  padding: 40px 40px 0px;
  @media (max-width: 1024px) {
    padding: 24px 24px 0px;
  }
`;

const RoleControls = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
`;

const styles = {
  modal: {
    '& .tab-container': {
      minHeight: '255px',
      backgroundColor: '#fefaf2',
      '& .tab-body': {
        minHeight: '200px',
      },
    },
  },
  dialog: {
    padding: '32px 0px 40px',
    backgroundColor: '#fefaf2',
    width: '540px',
  },
  closeBtn: {
    position: 'absolute' as any,
    top: '30px',
    right: '30px',
    cursor: 'pointer',
    '@media(max-device-width: 767px)': {
      top: '15px',
      right: '15px',
    },
  },
  title: {
    padding: '0 40px',
    '@media(max-device-width: 767px)': {
      padding: '0 20px',
    },
  },
  content: {
    margin: '0',
    padding: '0',
  },
  tab: {
    backgroundColor: '#fefaf2',
    width: '50%',
    textAlign: 'center' as any,
    '@media(max-device-width: 767px)': {
      padding: '20px 10px 10px!important',
    },
  },
  actions: {
    padding: '0 40px',
    '@media(max-device-width: 767px)': {
      justifyContent: 'space-between',
      fontSize: '10px',
    },
  },
  primaryBtn: {
    height: '40px',
    lineHeight: '40px',
    fontSize: '10px',
    '@media(max-device-width: 767px)': {
      padding: '0 10px',
      fontSize: 'inherit',
    },
  },
  secondaryBtn: {
    fontWeight: 'bold' as any,
    '@media(max-device-width: 767px)': {
      padding: '0 10px',
      fontSize: 'inherit',
      lineHeight: 'inherit',
    },
  },
};

export default withStyles(styles)(InviteToWheelModalComponent);

import { ArrowLeft } from 'react-feather';
import { BlackBtn, BlackButtonBox, ContentBox, LeftPanel, RedBtn } from '../Onboarding/_styled';
import { Text } from '../../Shared/Typography/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { analytics } from '../../../analytics/analytics';

const DateBirthForm = ({ formik, prevStep }) => {
  useEffect(() => {
    if (!formik.values.birthDate) {
      formik.setFieldValue('birthDate', dayjs());
    }
  }, [formik]);

  const addPersonalData = () => {
    analytics.clickOnbPersonalDataAdded();
  };

  return (
    <LeftPanel style={{ width: '100%' }}>
      <BlackButtonBox style={{ width: '100%' }}>
        <BlackBtn onClick={prevStep} style={{ color: '#2b2e31' }}>
          <ArrowLeft size={13} /> BACK
        </BlackBtn>
      </BlackButtonBox>
      <ContentBox style={{ width: '100%' }}>
        <Text weight={600} size="36px">
          What is your date of birth?
        </Text>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4 birth-date">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DatePicker
                label="DD/MM/YYYY"
                value={formik.values.birthDate}
                onChange={(date) => formik.setFieldValue('birthDate', date)}
              />
            </LocalizationProvider>
          </div>
          {formik.isValid && (
            <RedBtn type="submit" onClick={addPersonalData}>
              Sign Up
            </RedBtn>
          )}
        </form>
      </ContentBox>
    </LeftPanel>
  );
};

export default DateBirthForm;

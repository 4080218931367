import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Text } from '../Shared/Typography/Typography';
import SettingsForm from './SettingsForm/SettingsForm';
import edit from '../../Assets/images/onboarding/link.png';
import './Settings.scss';
import { StyledModal } from '../Dashboard/Sidebar';
import { ModalBody, ModalFooter } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';
import {
  AccountButton,
  BlackBtn,
  CheckContainer,
  ContentBox,
  ContentModalBox,
  OptionButton,
  Options,
  OptionsContainer,
  RedBtn,
  StyledModalOB,
} from '../Auth/Onboarding/_styled';
import { ArrowRight, Check } from 'react-feather';
import { questionnaireData } from '../Auth/Onboarding/constants';
import services from '../../API/services';
import { updatePersonalDataAction } from '../Auth/Auth.actions';
import { RootState } from '../../Redux/Reducers';
import { useHistory } from 'react-router-dom';
import { EthnicityField, GenderField, NationalityField } from './ProfileFields';
import { analytics } from '../../analytics/analytics';

interface ProfileProps extends PropsFromRedux {}

const Profile: React.FC<ProfileProps> = ({ user, updatePersonalDataAction }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalField, setModalField] = useState('');
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [step, setStep] = useState(1);
  const [userType, setUserType] = useState(user.personalData?.userTag || '');
  const [selectedOptions, setSelectedOptions] = useState(user.personalData?.userRole || []);
  const [unsavedUserType, setUnsavedUserType] = useState(user.personalData?.userTag || '');
  const [unsavedSelectedOptions, setUnsavedSelectedOptions] = useState(user.personalData?.userRole || []);
  const [gender, setGender] = useState(user.personalData?.gender || '');
  const [ethnicity, setEthnicity] = useState(user.personalData?.ethnicity || '');
  const [nationality, setNationality] = useState(user.personalData?.nationality || '');
  const [customGender, setCustomGender] = useState(user.personalData?.customGender || []);
  const [customEthnicity, setCustomEthnicity] = useState(user.personalData?.customEthnicity || []);
  const history = useHistory();

  useEffect(() => {
    resetState();
  }, [user]);

  const resetState = () => {
    setUserType(user.personalData?.userTag || '');
    setSelectedOptions(user.personalData?.userRole || []);
    setUnsavedUserType(user.personalData?.userTag || '');
    setUnsavedSelectedOptions(user.personalData?.userRole || []);
    setGender(user.personalData?.gender || '');
    setEthnicity(user.personalData?.ethnicity || '');
    setNationality(user.personalData?.nationality || '');
    setCustomGender(user.personalData?.customGender || []);
    setCustomEthnicity(user.personalData?.customEthnicity || []);
  };

  const handleSaveChanges = async () => {
    const updatedPersonalData = {
      ...user.personalData,
      gender,
      ethnicity,
      nationality,
      customGender,
      customEthnicity,
      userTag: unsavedUserType,
      userRole: unsavedSelectedOptions,
      reason: unsavedUserType !== userType ? [] : user.personalData?.reason || [],
      goals: unsavedUserType !== userType ? [] : user.personalData?.goals || [],
      challenges: unsavedUserType !== userType ? [] : user.personalData?.challenges || [],
      resources: unsavedUserType !== userType ? [] : user.personalData?.resources || [],
      focus: unsavedUserType !== userType ? [] : user.personalData?.focus || [],
    };

    try {
      await services.updatePersonalData(updatedPersonalData);
      updatePersonalDataAction(updatedPersonalData);
      setShowCreateFolderModal(false);
      setStep(1);
      setShowModal(false);
      setModalField('');
    } catch (error) {
      console.error('Failed to update personal data:', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowCreateFolderModal(false);
    setModalField('');
    setStep(1);
  };

  const handleAccountSelect = (type: string) => {
    setUnsavedUserType(type);
    setUnsavedSelectedOptions([]);
  };

  const handleCheckboxChange = (option: string) => {
    setUnsavedSelectedOptions((prevOptions) => {
      if (prevOptions.includes(option)) {
        return prevOptions.filter((item) => item !== option);
      } else {
        if (unsavedUserType === 'Education' && prevOptions.length >= 1) {
          return prevOptions;
        }
        if (unsavedUserType !== 'Education' && prevOptions.length >= 3) {
          return prevOptions;
        }
        return [...prevOptions, option];
      }
    });
  };

  const handleNext = () => {
    if (step < 3 && (step !== 2 || hasUserRoleQuestion())) {
      analytics.clickProfileUpdateStart();
      setStep(step + 1);
    } else {
      handleSaveChanges();
      analytics.clickProfileUpdateComplete();
    }
  };

  const hasUserRoleQuestion = () => {
    return questionnaireData[unsavedUserType]?.some((question) => question.name === 'userRole');
  };

  const handleEditUserRole = () => {
    setStep(3);
    setShowCreateFolderModal(true);
  };

  const renderAccountTagSection = () => (
    <div className="info-item">
      <div className="d-flex flex-column">
        <Text size="14px">User tag</Text>
        <Text size="16px" weight={700}>
          {user.personalData?.userTag !== null ? user.personalData.userTag : 'Not selected'}
        </Text>
      </div>
      <button className="edit-button" onClick={() => setShowCreateFolderModal(true)}>
        <img src={edit} alt="Edit" />
        Edit
      </button>
    </div>
  );

  const renderUserRoleSection = () =>
    user.personalData?.userRole?.length > 0 && (
      <div className="info-item">
        <div className="d-flex flex-column">
          <Text size="14px">User role</Text>
          <Text size="16px" weight={700}>
            {user.personalData.userRole.join(', ')}
          </Text>
        </div>
        <button className="edit-button" onClick={handleEditUserRole}>
          <img src={edit} alt="Edit" />
          Edit
        </button>
      </div>
    );

  const renderInfoItem = (label, value, field) => (
    <div className="info-item">
      <div className="d-flex flex-column">
        <Text size="14px">{label}</Text>
        <Text size="16px" weight={700}>
          {value || 'Not selected'}
        </Text>
      </div>
      <button
        className="edit-button"
        onClick={() => {
          setShowModal(true);
          setModalField(field);
        }}
      >
        <img src={edit} alt="Edit" />
        Edit
      </button>
    </div>
  );

  const renderModalContent = () => {
    switch (modalField) {
      case 'gender':
        return (
          <GenderField
            gender={gender}
            setGender={setGender}
            customGender={customGender}
            setCustomGender={setCustomGender}
          />
        );
      case 'nationality':
        return <NationalityField nationality={nationality} setNationality={setNationality} />;
      case 'ethnicity':
        return (
          <EthnicityField
            ethnicity={ethnicity}
            setEthnicity={setEthnicity}
            customEthnicity={customEthnicity}
            setCustomEthnicity={setCustomEthnicity}
          />
        );
      default:
        return null;
    }
  };

  const renderModalStep1 = () => (
    <>
      <Text className="mb-4" weight={600} size="30px" color="#2b2e31">
        Are you sure you want to edit your profile data?
      </Text>
      <br />
      <Text size="14px">
        Editing your profile will reset your previously saved history, including your preferences and goals.
      </Text>
    </>
  );

  const renderModalStep2 = () => (
    <>
      <div className="d-flex justify-content-end mb-4">
        <FaTimes className="close-icon" onClick={handleCloseModal} style={{ cursor: 'pointer' }} />
      </div>
      <ContentModalBox style={{ width: '100%' }}>
        <Text className="pb-2" weight={600} size="30px" color="#2b2e31">
          What is your main use?
        </Text>
        {Object.keys(questionnaireData).map((account) => (
          <AccountButton
            key={account}
            selected={unsavedUserType === account}
            onClick={() => handleAccountSelect(account)}
          >
            <Text weight={500} size="18px">
              {account}
            </Text>
          </AccountButton>
        ))}
      </ContentModalBox>
    </>
  );

  const renderModalStep3 = () => (
    <>
      <div className="d-flex justify-content-end mb-4">
        <FaTimes className="close-icon" onClick={handleCloseModal} style={{ cursor: 'pointer' }} />
      </div>
      <ContentModalBox style={{ width: '100%' }}>
        <Text className="mb-2" weight={600} size="30px" color="#2b2e31">
          {questionnaireData[unsavedUserType]?.find((question) => question.name === 'userRole')?.question ||
            'What type of work are you involved with that you may use the platform for?'}
        </Text>
        <Text size="14px">
          {unsavedUserType === 'Education' ? 'Select 1 option' : 'You can select up to 3 options'}
        </Text>
        <Options>
          <OptionsContainer>
            {questionnaireData[unsavedUserType]
              ?.find((question) => question.name === 'userRole')
              ?.options.map((option, index) => (
                <OptionButton
                  key={index}
                  selected={unsavedSelectedOptions.includes(option)}
                  onClick={() => handleCheckboxChange(option)}
                >
                  <CheckContainer>{unsavedSelectedOptions.includes(option) && <Check size={13} />}</CheckContainer>
                  {option}
                </OptionButton>
              ))}
          </OptionsContainer>
        </Options>
      </ContentModalBox>
    </>
  );

  const completeOnboarding = () => {
    history.push('/onboarding');
  };

  return (
    <div className="settings-tab">
      <div className="edit-container">
        <Text size="18px" weight={500}>
          Edit your data
        </Text>
        <button className="edit-button onboarding-btn" onClick={completeOnboarding}>
          Complete onboarding
        </button>
        <SettingsForm user={user} fields={['firstName', 'lastName', 'birth']} />
      </div>
      <div className="info-card">
        {renderInfoItem('Gender', user.personalData?.gender, 'gender')}
        {renderInfoItem('Nationality', user.personalData?.nationality, 'nationality')}
        {renderInfoItem('Ethnicity', user.personalData?.ethnicity, 'ethnicity')}
        {renderAccountTagSection()}
        {renderUserRoleSection()}
      </div>
      <StyledModalOB id="styled-modal-ob" isOpen={showCreateFolderModal} centered>
        <ModalBody style={{ padding: '5px 2px' }}>
          {step === 1 && renderModalStep1()}
          {step === 2 && renderModalStep2()}
          {step === 3 && hasUserRoleQuestion() && renderModalStep3()}
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none', marginTop: '25px', padding: '5px 2px' }}>
          <BlackBtn onClick={handleCloseModal}>Cancel</BlackBtn>
          {step === 1 ? (
            <button onClick={handleNext} style={{ marginLeft: '10px' }} className="exit-btn">
              Edit Profile
            </button>
          ) : (
            <RedBtn
              onClick={handleNext}
              style={{
                marginLeft: '10px',
                ...(step === 3 && unsavedSelectedOptions.length < 1 ? { opacity: 0.4 } : {}),
              }}
              disabled={step === 3 && unsavedSelectedOptions.length < 1}
            >
              {step === 2 && (unsavedUserType === 'Personal' || unsavedUserType === 'Other') ? (
                'Save Changes'
              ) : step < 3 ? (
                <>
                  Next <ArrowRight size={13} />
                </>
              ) : (
                'Save Changes'
              )}
            </RedBtn>
          )}
        </ModalFooter>
      </StyledModalOB>
      <StyledModalOB id="styled-modal-ob" isOpen={showModal} centered>
        <ModalBody style={{ padding: '5px 2px' }}>
          <div className="d-flex justify-content-end">
            <FaTimes className="close-icon" onClick={handleCloseModal} style={{ cursor: 'pointer' }} />
          </div>
          {renderModalContent()}
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none', marginTop: '25px', padding: '5px 2px' }}>
          <BlackBtn onClick={handleCloseModal}>Cancel</BlackBtn>
          <RedBtn onClick={handleSaveChanges} style={{ marginLeft: '10px' }}>
            Save Changes
          </RedBtn>
        </ModalFooter>
      </StyledModalOB>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.userRD.user || {},
});

const mapDispatchToProps = {
  updatePersonalDataAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Profile);

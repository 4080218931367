import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Breakpoint } from 'react-socks';

import services from '../../API/services';
import { AccountPlan, Folder, iFolder, iWheelExt, iWheelExtMapped } from '../../API/interfaces';

import { analytics } from '../../analytics/analytics';
import { Guard } from '../../_utils/Guard';

import utils, { isWheelAdmin } from '../Shared/utils';

import { ManageContainer } from '../_Containers/ManageContainer';
import { educationService } from '../Education/Education.service';
import { EducationStatus } from '../Education/Education.model';

import {
  applySearchCurried,
  applyTagsCurried,
  shallApplySearch,
  wasSortApplied,
  wasTagApplied,
  wheelComparerCurried,
  toExtendedWheel,
  folderWheelsContainsCurried,
} from './_utils';
import { eSortOptions, eWheelFilterTags } from './_types';
import { FILTER_INITIAL_VALUE } from './_consts';
import { SidebarMobile, SidebarDesktop } from './Sidebar';
import { DashboardDesktop, DashboardMobile } from './Body';
import { StringParam, useQueryParam } from 'use-query-params';
import { AxiosError, AxiosResponse } from 'axios';
import { FolderClient } from '../../API/folder.client';
import { toasterService } from '../Shared/Toaster/Toaster.service';
import CountableTextBox from '../Shared/CountableTextBox/CountableTextBox';
import { MAX_WHEEL_NAME_LENGTH } from '../../constants';
import { StyledModal } from './Sidebar/SidebarDesktop';
import {
  StyledModalBody,
  StyledModalFooter,
  StyledModalHeader,
  StyledMainButton,
  StyledSecondaryButton,
} from './FolderModals/_styled';
import { MembersClient } from '../../API/members.client';
import { loadWheelsSuccess } from './Dashboard.actions';
import InviteToWheelModal from '../WheelPage/WheelInviteModal/InviteToWheelModal';

interface iDashboardProps extends RouteComponentProps<any> {
  user: any;
  shareLinkId: string | null;
  isMobile: string;
  isSmallSC: string;
  firstVisitOfDashboard: EducationStatus;
  actions: {
    setEducationFlow: (config) => void;
    setFirstVisitOfDashboard: (string: EducationStatus) => void;
  };
}

export enum Domains {
  WHEELS = 'WHEELS',
  FOLDERS = 'FOLDERS',
}

// TODO move filter, search etc to query params
const Dashboard = (props: iDashboardProps) => {
  const [selectedFolderId, setSelectedFolderId] = useQueryParam('folder_id', StringParam);

  const user = useSelector((state: any) => (state.userRD.user ? state.userRD.user : null));
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  const folderQuery = useQuery<AxiosResponse<iFolder[]>, AxiosError, Folder[]>(Domains.FOLDERS, FolderClient.get, {
    select: ({ data }) => (data ? data.map(Folder.of) : []),
  });

  const tryToRedirectToTemplates = React.useCallback(() => {
    const KEY = 'template_redirect';
    const shouldRedirect = !sessionStorage.getItem(KEY);

    // if null - redirect user to template page (first visit of dashboard in this session)
    if (shouldRedirect) {
      sessionStorage.setItem(KEY, '1');
      history.push('/templates');
    }
  }, []);

  const wheelQuery = useQuery<AxiosResponse<iWheelExt[]>, AxiosError, iWheelExtMapped[]>(
    Domains.WHEELS,
    () => {
      utils.startLoading();
      return services.getWheelsExtendedByUserId();
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      select: ({ data }) => data.map(toExtendedWheel.bind(null, user._id)),
      onSuccess: (data) => {
        if (data.length) {
          dispatch(loadWheelsSuccess({ wheels: data }));
          educationService.processDashboardEducation();
        } else {
          tryToRedirectToTemplates();
        }
      },
      onSettled: () => utils.endLoading(),
    }
  );
  const wheels = React.useMemo(() => wheelQuery.data || [], [wheelQuery.data]);
  const [wheelToDuplicate, setWheelToDuplicate] = React.useState<iWheelExt | null>(null);
  const [wheelToLeave, setWheelToLeave] = React.useState<iWheelExt | null>(null);
  const [wheelToInvite, setWheelToInvite] = React.useState<iWheelExt | null>(null);
  const [wheelToDelete, setWheelToDelete] = React.useState<iWheelExt | null>(null);
  const [filter, setFilter] = React.useState(FILTER_INITIAL_VALUE);
  const [query, setQuery] = React.useState('');
  const [sort, setSort] = React.useState({});

  const folderAddWheel = useMutation(FolderClient.addWheel);

  const selectedFolder = React.useMemo(() => folderQuery.data?.find(({ id }) => id === selectedFolderId), [
    folderQuery,
    selectedFolderId,
  ]);

  const filteredWheels = React.useMemo(() => {
    let result = wheels;

    if (selectedFolder) {
      result = result.filter(folderWheelsContainsCurried(selectedFolder));
    }

    if (shallApplySearch(query)) {
      result = result.filter(applySearchCurried(query));
    }

    if (wasTagApplied(filter)) {
      result = result.filter(applyTagsCurried(filter, user.id));
    }

    if (wasSortApplied(sort)) {
      result.sort(wheelComparerCurried(sort));
    }

    const personalWheel = result.find((w) => w.isPersonal);
    if (personalWheel) {
      result = [personalWheel, ...result.filter((w) => w !== personalWheel)];
    }

    return result;
  }, [wheels, query, filter, sort, user.id, selectedFolder]);

  const showNoWheelsHaveBeenCreated = React.useMemo(() => {
    return _.isEmpty(wheels);
  }, [wheels]);

  const showNothingFound = React.useMemo(() => {
    return _.isEmpty(filteredWheels) && Boolean(shallApplySearch(query) || wasTagApplied(filter) || selectedFolderId);
  }, [query, filter, filteredWheels, selectedFolderId]);

  // cbs
  const onFilterTagClick = React.useCallback(
    (tag: eWheelFilterTags) => {
      setFilter({ ...filter, [tag]: !filter[tag] });
    },
    [filter]
  );

  const onWheelRemoved = React.useCallback((deletedWheel: iWheelExt) => {
    const updatedWheels = wheels.filter((wheel) => wheel !== updatedWheels);

    queryClient.invalidateQueries(Domains.WHEELS);
  }, []);

  const onWheelDuplicate = React.useCallback(
    (newName) => {
      if (wheelToDuplicate) {
        services
          .duplicateWheel(wheelToDuplicate.id, newName)
          .then(() => {
            analytics.duplicate_wheel();
            queryClient.invalidateQueries(Domains.WHEELS);
          })
          .finally(() => setWheelToDuplicate(null));
      }
    },
    [wheelToDuplicate]
  );

  const onDeleteWheel = React.useCallback(() => {
    services
      .deleteWheel(wheelToDelete.id)
      .then(() => {
        analytics.deleteWheel();
        queryClient.invalidateQueries(Domains.WHEELS);
      })
      .catch((res) => toasterService.addErrorToast(res.data.message))
      .finally(() => setWheelToDelete(null));
  }, [wheelToDelete]);

  const onLeaveWheel = React.useCallback(() => {
    MembersClient.deleteWheelMember({
      wheelId: wheelToLeave.id,
      userId: user._id,
    })
      .then(() => {
        analytics.leaveWheel();
        queryClient.invalidateQueries(Domains.WHEELS);
      })
      .catch((res) => toasterService.addErrorToast(res.data.message))
      .finally(() => setWheelToLeave(null));
  }, [wheelToLeave]);

  const onCreateWheel = React.useCallback(() => {
    analytics.createWheelAttempt();

    history.push('/create-wheel');
  }, []);

  const moveWheelToFolder = React.useCallback((folder: Folder, wheel: iWheelExt) => {
    folderAddWheel.mutate(
      { folderId: folder.id, wheelId: wheel.id },
      {
        onSuccess: () => {
          toasterService.addSuccessToast(`Wheel ${wheel.name} has been successfully moved to ${folder.name}`);
          setSelectedFolderId(folder.id);
          queryClient.invalidateQueries(Domains.FOLDERS);
          analytics.moveToFolder();
        },
        onError: () => {
          toasterService.addErrorToast('Something went wrong');
        },
      }
    );
  }, []);

  const onSortChanged = React.useCallback((sortValue) => {
    if (sortValue && Object.keys(sortValue).length)
      analytics.dashboardSorting(Object.keys(sortValue)[0] as eSortOptions);
    setSort(sortValue);
  }, []);

  return (
    <ManageContainer className="gray without-footer" hasFooter={false}>
      <Breakpoint small up style={{ display: 'flex', minHeight: 'calc(100vh - 70px)' }}>
        <Guard.Component allow={[AccountPlan.BUSINESS, AccountPlan.SCHOOL, AccountPlan.PERFORMANCE]}>
          <SidebarDesktop />
        </Guard.Component>

        <DashboardDesktop
          filter={filter}
          onFilterTagClick={onFilterTagClick}
          onWheelRemoved={onWheelRemoved}
          setSort={onSortChanged}
          setQuery={setQuery}
          sort={sort}
          showWheelDuplicateModal={setWheelToDuplicate}
          showNoWheelsHaveBeenCreated={showNoWheelsHaveBeenCreated}
          showNothingFound={showNothingFound}
          setWheelToDelete={setWheelToDelete}
          setWheelToInvite={setWheelToInvite}
          setWheelToLeave={setWheelToLeave}
          user={user}
          query={query}
          wheels={filteredWheels}
          history={history}
          onCreateWheel={onCreateWheel}
          folders={folderQuery.data}
          onMoveWheelToFolder={moveWheelToFolder}
        />
      </Breakpoint>

      <Breakpoint small down>
        <Guard.Component allow={[AccountPlan.BUSINESS, AccountPlan.SCHOOL, AccountPlan.PERFORMANCE]}>
          <SidebarMobile />
        </Guard.Component>

        <DashboardMobile
          filter={filter}
          onFilterTagClick={onFilterTagClick}
          onWheelRemoved={onWheelRemoved}
          setSort={onSortChanged}
          setQuery={setQuery}
          sort={sort}
          showWheelDuplicateModal={setWheelToDuplicate}
          showNoWheelsHaveBeenCreated={showNoWheelsHaveBeenCreated}
          showNothingFound={showNothingFound}
          setWheelToDelete={setWheelToDelete}
          setWheelToInvite={setWheelToInvite}
          setWheelToLeave={setWheelToLeave}
          user={user}
          query={query}
          wheels={filteredWheels}
          history={history}
          onCreateWheel={onCreateWheel}
          folders={folderQuery.data}
          onMoveWheelToFolder={moveWheelToFolder}
        />
      </Breakpoint>

      <StyledModal isOpen={Boolean(wheelToDuplicate)} centered>
        <DuplicateWheel wheel={wheelToDuplicate} confirm={onWheelDuplicate} cancel={() => setWheelToDuplicate(null)} />
      </StyledModal>

      <StyledModal isOpen={Boolean(wheelToDelete)} centered>
        <DeleteWheel wheel={wheelToDelete} confirm={onDeleteWheel} cancel={() => setWheelToDelete(null)} />
      </StyledModal>

      <StyledModal isOpen={Boolean(wheelToLeave)} centered>
        <LeaveWheel
          wheel={wheelToLeave}
          confirm={onLeaveWheel}
          cancel={() => setWheelToLeave(null)}
          userId={user._id}
        />
      </StyledModal>

      <InviteToWheelModal wheel={wheelToInvite} userId={user._id} onCancel={() => setWheelToInvite(null)} />
    </ManageContainer>
  );
};

export default Dashboard;

// TODO
//  do something with modals
//  problem multiple implementations
//  add storybook

const DuplicateWheel = ({ wheel, cancel, confirm }) => {
  const defaultName = `${wheel?.name} (Copy)`;
  const [wheelName, setWheelName] = useState(defaultName.length > MAX_WHEEL_NAME_LENGTH ? wheel?.name : defaultName);

  return (
    <>
      <StyledModalHeader toggle={cancel}>Duplicate wheel</StyledModalHeader>
      <StyledModalBody>
        <div>{`Please, enter a name for a new wheel. Members and scores will not be copied`}</div>
        <form onSubmit={() => confirm(wheelName)} className="mt-2">
          <CountableTextBox
            placeholder={`Enter a Duplicate name`}
            value={wheelName}
            max={MAX_WHEEL_NAME_LENGTH}
            onChange={(e) => setWheelName(e.target.value as string)}
          />
        </form>
      </StyledModalBody>
      <StyledModalFooter>
        <StyledSecondaryButton onClick={cancel}>Cancel</StyledSecondaryButton>
        <StyledMainButton onClick={() => confirm(wheelName)}>Duplicate</StyledMainButton>
      </StyledModalFooter>
    </>
  );
};

const DeleteWheel = ({ wheel, cancel, confirm }) => (
  <>
    <StyledModalHeader toggle={cancel}>Delete wheel</StyledModalHeader>
    <StyledModalBody>{`Are you sure you want to delete the wheel "${wheel?.name}"?`}</StyledModalBody>
    <StyledModalFooter>
      <StyledSecondaryButton onClick={cancel}>Cancel</StyledSecondaryButton>
      <StyledMainButton onClick={confirm}>Confirm</StyledMainButton>
    </StyledModalFooter>
  </>
);

const LeaveWheel = ({ wheel, cancel, confirm, userId }) => {
  const isAdmin = isWheelAdmin(wheel, userId);
  return (
    <>
      <StyledModalHeader toggle={cancel}>Leave the wheel</StyledModalHeader>
      <StyledModalBody>{`Are you sure you want to delete yourself from the wheel? You will not see "${
        wheel?.name
      }" any longer.${isAdmin ? ' The data will be available for other admins' : ''}`}</StyledModalBody>
      <StyledModalFooter>
        <StyledSecondaryButton onClick={cancel}>Cancel</StyledSecondaryButton>
        <StyledMainButton onClick={confirm}>Confirm</StyledMainButton>
      </StyledModalFooter>
    </>
  );
};

import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Text } from '../Shared/Typography/Typography';
import plus from '../../Assets/images/onboarding/plus.png';
import {
  Options,
  OptionsContainer,
  OptionButton,
  CheckContainer,
  CustomOptionContainer,
  FieldWrapper,
  GoalsOptionsContainer,
} from '../Auth/Onboarding/_styled';
import { Check } from 'react-feather';
import { countries } from '../Auth/Onboarding/constants';

export const GenderField = ({ gender, setGender, customGender, setCustomGender }) => {
  const [customOption, setCustomOption] = useState('');

  const handleCheckboxChange = (option) => {
    setGender((prevSelected) => (prevSelected === option ? '' : option));
  };

  const handleCustomOptionAdd = () => {
    if (customOption && !customGender.includes(customOption)) {
      setCustomGender([...customGender, customOption]);
      setGender(customOption);
      setCustomOption('');
    }
  };

  return (
    <FieldWrapper>
      <div>
        <Text weight={600} size="32px" className="mb-2">
          Which most accurately describes you?
        </Text>
        <br />
        <Text size="14px">Select one option</Text>
      </div>
      <Options>
        <OptionsContainer className="mb-2">
          {['Male', 'Female', 'Non-binary', 'Prefer not to answer', ...customGender].map((option, index) => (
            <OptionButton key={index} selected={gender === option} onClick={() => handleCheckboxChange(option)}>
              <CheckContainer>{gender === option && <Check size={13} />}</CheckContainer>
              {option}
            </OptionButton>
          ))}
        </OptionsContainer>
        <CustomOptionContainer className="mb-2" id="custom-option">
          <TextField
            fullWidth
            value={customOption}
            onChange={(e) => setCustomOption(e.target.value)}
            multiline={false}
            id="outlined-basic"
            label="Another option"
            variant="outlined"
            InputProps={{
              endAdornment: customOption ? (
                <img src={plus} alt="plus" width="52" height="52" onClick={handleCustomOptionAdd} />
              ) : null,
            }}
          />
        </CustomOptionContainer>
      </Options>
    </FieldWrapper>
  );
};

export const NationalityField = ({ nationality, setNationality }) => (
  <FieldWrapper>
    <Text className="mb-2" weight={600} size="32px">
      What is your nationality?
    </Text>
    <Autocomplete
      fullWidth
      options={countries}
      getOptionLabel={(option) => option}
      value={nationality}
      onChange={(event, newValue) => setNationality(newValue || '')}
      renderInput={(params) => <TextField {...params} label="Nationality" variant="outlined" placeholder="Enter" />}
      className="mb-2"
    />
  </FieldWrapper>
);

export const EthnicityField = ({ ethnicity, setEthnicity, customEthnicity, setCustomEthnicity }) => {
  const [customOption, setCustomOption] = useState('');

  const handleCheckboxChange = (option) => {
    setEthnicity((prevSelected) => (prevSelected === option ? '' : option));
  };

  const handleCustomOptionAdd = () => {
    if (customOption && !customEthnicity.includes(customOption)) {
      setCustomEthnicity([...customEthnicity, customOption]);
      setEthnicity(customOption);
      setCustomOption('');
    }
  };

  return (
    <FieldWrapper>
      <div>
        <Text className="mb-2" weight={600} size="32px">
          Please describe your race/ethnicity
        </Text>
        <br />
        <Text size="14px">Select one option</Text>
      </div>
      <Options>
        <GoalsOptionsContainer className="mb-2">
          {[
            'Aboriginal and Torres Strait Islander',
            'Caucasian',
            'African',
            'Asian',
            'Middle Eastern',
            'Pacific Islander',
            'Hispanic or Latino',
            'I prefer not to say',
            ...customEthnicity,
          ].map((option, index) => (
            <OptionButton key={index} selected={ethnicity === option} onClick={() => handleCheckboxChange(option)}>
              <CheckContainer>{ethnicity === option && <Check size={13} />}</CheckContainer>
              {option}
            </OptionButton>
          ))}
        </GoalsOptionsContainer>
        <CustomOptionContainer className="mb-2">
          <TextField
            fullWidth
            value={customOption}
            onChange={(e) => setCustomOption(e.target.value)}
            multiline={false}
            id="outlined-basic"
            label="Another option"
            variant="outlined"
            InputProps={{
              endAdornment: customOption ? (
                <img src={plus} alt="plus" width="52" height="52" onClick={handleCustomOptionAdd} />
              ) : null,
            }}
          />
        </CustomOptionContainer>
      </Options>
    </FieldWrapper>
  );
};

import React, { useEffect, useState } from 'react';
import { AuthContainer } from '../AuthContainer';
import './SignUpForm.scss';
import { Checkbox } from '../../Shared/Inputs/Checkbox';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import services from '../../../API/services';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button } from '../../Shared/Buttons/Button';
import { Toast } from '../../Shared/Toaster/Toast';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Text } from '../../Shared/Typography/Typography';
import { analytics } from '../../../analytics/analytics';
import { ToastTypes } from '../../Shared/Toaster/Toaster.actions';
import { authService } from '../Auth.service';
import { SignInButtonMS } from '../SSO/msal/SignInButtonMS';
import { Divider, DividerContent } from '../SSO/Divider';
import { SignInButtonOkta } from '../SSO/okta/SignInButtonOkta';
import { AccountType } from '../../../API/interfaces';
import { resetInvitationLink } from '../../Invitations/invitation.actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectInvitationLink } from '../../Invitations/invitation.selectors';
import DateBirthForm from './DateBirthForm';
import NameForm from './NameForm';
import { TextField } from '@material-ui/core';
import { BtnGroup, AuthContentBox, LeftPanel, RedBtn } from '../Onboarding/_styled';
import { ArrowRight } from 'react-feather';
import eye from '../../../Assets/images/onboarding/eye.svg';
import eyeOff from '../../../Assets/images/onboarding/eye-off.svg';

type iSignUpProps = RouteComponentProps;

const initialValues = {
  policyChecked: false,
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  birthDate: null,
};

const validationSchemaStep1 = yup.object({
  email: yup.string().email('Not valid email format').required('The field can’t be empty'),
  password: yup.string().min(6, 'Password must contain at least 6 characters').required('The field can’t be empty'),
  policyChecked: yup
    .boolean()
    .required('The field can’t be empty')
    .oneOf([true], 'Please, agree with Terms&Conditions and Privacy Policy'),
});

const validationSchemaStep2 = yup.object({
  firstName: yup.string().trim().max(50, 'The value of the field is too long').required('The field can’t be empty'),
  lastName: yup.string().trim().max(50, 'The value of the field is too long').required('The field can’t be empty'),
});

const validationSchemaStep3 = yup.object({
  birthDate: yup.date().nullable().required('The field can’t be empty'),
});

const SignUpComponent = ({ history, location }: iSignUpProps) => {
  const [serverError, setServerError] = useState('');
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const [showPassword, setShowPassword] = useState(false);

  const tagParam = new URLSearchParams(location.search).get('tag') as AccountType | null;
  const accountTag: AccountType = tagParam ? tagParam : AccountType.PERSONAL_BUSINESS;
  const invitationLink = useSelector(selectInvitationLink);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!tagParam) {
      analytics.onboardingCustomEvent();
    }
  }, []);

  useEffect(() => {
    formik.resetForm();
  }, [location.pathname]);

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const next = () => {
    if (!tagParam) {
      history.push('/onboarding');
    } else {
      if (invitationLink) {
        dispatch(resetInvitationLink());
        history.push(invitationLink);
      } else {
        history.push('/dashboard');
      }
    }
  };

  const login = async ({ email, password }) => {
    const loginResponse = await services.userLogin({ email, password });
    if (loginResponse.status === 200) {
      authService.login({
        token: loginResponse.data.access_token,
        ...loginResponse.data.user,
      });
      next();
    } else {
      setServerError(loginResponse.data.message);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    if (formik.isValid && formik.dirty && !loading) {
      const response = await services.userSignUp({ accountTag, ...values });
      if (response.status === 200) {
        await login(values);
        analytics.signUp();
      } else {
        setServerError(response.data.message);
      }
      setLoading(false);
    }
  };

  const loginSSO = async (response) => {
    if (response.status === 200) {
      analytics.signUpSSO();
      authService.login({
        token: response.data.access_token,
        ...response.data.user,
      });
      setModal(true);
    } else {
      setServerError(response.data.message);
    }
  };

  const getValidationSchema = (step) => {
    switch (step) {
      case 1:
        return validationSchemaStep1;
      case 2:
        return validationSchemaStep2;
      case 3:
        return validationSchemaStep3;
      default:
        return validationSchemaStep1;
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getValidationSchema(step),
    onSubmit: handleSubmit,
  });

  const loginLinkEvent = () => {
    analytics.loginLink();
  };

  const SuccessModal = (
    <div>
      <Modal isOpen={modal} toggle={next}>
        <ModalHeader toggle={next}>Welcome to iyarn!</ModalHeader>
        <ModalBody>
          <Text>Check your email {formik.values.email} for tips to make the most of iyarn.</Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={next}>Ok</Button>
        </ModalFooter>
      </Modal>
    </div>
  );

  const buildSignUpLink = () => {
    switch (tagParam) {
      case AccountType.ATHLETIC_WEST:
        return '/login?tag=athletic_west';
      case AccountType.SKILLHIRE:
        return '/login?tag=skillhire';
      case AccountType.WUNAN:
        return '/login?tag=wunan';
      case AccountType.METATTUDE:
        return '/login?tag=metattude';
      default:
        return '/login';
    }
  };

  return (
    <AuthContainer>
      <div className="signup">
        {serverError && (
          <div className="mb-4">
            <Toast type={ToastTypes.ERROR} message={serverError} />
          </div>
        )}
        {step === 1 && (
          <LeftPanel style={{ width: '100%' }}>
            <AuthContentBox>
              <Text weight={600} size="36px">
                Sign Up
              </Text>
              <div className="mb-4">
                <Text size="14px">
                  Already have an account?{' '}
                  <Link className="signup_login-link" to={buildSignUpLink()} onClick={loginLinkEvent}>
                    Log In
                  </Link>
                </Text>
              </div>
              {tagParam !== AccountType.SKILLHIRE &&
                tagParam !== AccountType.ATHLETIC_WEST &&
                tagParam !== AccountType.WUNAN &&
                tagParam !== AccountType.METATTUDE && (
                  <>
                    <BtnGroup>
                      <SignInButtonMS submitSSO={loginSSO}>Microsoft</SignInButtonMS>
                      <SignInButtonOkta submitSSO={loginSSO} />
                    </BtnGroup>
                    <div>
                      <Divider>
                        <DividerContent>or</DividerContent>
                      </Divider>
                    </div>
                  </>
                )}
              <form onSubmit={formik.handleSubmit} style={{ width: '100%', marginTop: '34px' }}>
                <div className="mb-2 d-flex flex-column" style={{ gap: '30px' }}>
                  <TextField
                    id="email-signup"
                    label="Email"
                    type="email"
                    variant="outlined"
                    placeholder="Enter Your Email"
                    value={formik.values.email}
                    onChange={formik.handleChange('email')}
                    onBlur={formik.handleBlur('email')}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  <TextField
                    id="password-signup"
                    label="Password"
                    placeholder="Enter Your Password"
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    value={formik.values.password}
                    onChange={formik.handleChange('password')}
                    onBlur={formik.handleBlur('password')}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    InputProps={{
                      endAdornment: (
                        <img
                          style={{ cursor: 'pointer', padding: '0 10px' }}
                          src={showPassword ? eye : eyeOff}
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        />
                      ),
                    }}
                  />
                </div>
                <div className="d-flex mb-4">
                  <div className="mr-2">
                    <Checkbox
                      id="policyChecked"
                      className="signup_checkbox"
                      name="policyChecked"
                      checked={formik.values.policyChecked}
                      onChange={formik.handleChange('policyChecked')}
                      onBlur={formik.handleBlur('policyChecked')}
                      error={formik.errors.policyChecked}
                      touched={formik.touched.policyChecked}
                    >
                      <span className="signup_policy">
                        I have read and accept the{' '}
                        <a
                          target="_blank"
                          className="signup_policy-link"
                          href="https://iyarn.com/terms-and-conditions/"
                          rel="noreferrer"
                        >
                          Terms & Conditions
                        </a>{' '}
                        <a
                          target="_blank"
                          className="signup_policy-link"
                          href="https://iyarn.com/privacy/"
                          rel="noreferrer"
                        >
                          Privacy & Policy
                        </a>
                      </span>
                    </Checkbox>
                  </div>
                </div>
                {!formik.errors.email && !formik.errors.password && formik.values.policyChecked && (
                  <RedBtn onClick={nextStep}>
                    Next <ArrowRight size={13} />
                  </RedBtn>
                )}
              </form>
            </AuthContentBox>
          </LeftPanel>
        )}
        {step === 2 && <NameForm formik={formik} prevStep={prevStep} nextStep={nextStep} />}
        {step === 3 && <DateBirthForm formik={formik} prevStep={prevStep} />}
      </div>
      {SuccessModal}
    </AuthContainer>
  );
};

export const SignUpForm = withRouter(SignUpComponent);
